@import "colors.scss";

.compact {
  background: rgb(255, 255, 255);
  width: fit-content;
  margin-left: 5px;
  border-radius: 4px;
  padding-right: 10px;
  margin-bottom: 5px;
  max-height:300px;
  overflow-y:auto;
}

.listContainer {
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px rgba(255, 255, 255, 0.8);
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }

  table {
    width: 100%;
    text-align: left;
    padding: 5px;
    & .center {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    & svg {
      padding: 2px;
      border-radius: 5px;
    }
    & .add,
    & .remove {
      opacity: 1;
      cursor: pointer;
      border-radius: 5px;
    }
    & .add {
      background-color: $success-light;
    }
    & .remove {
      background-color: $danger-light;
    }
  }
}
