.supervisorButtons{
  width: 150px;
  height: 30px;
  background: #0085FF;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;

}

.supervisorButtons > * {
    padding: 0;
}
