.canvasContainer {
  position: relative;
  flex: 1;
  overflow: hidden;
  z-index: 0;
  margin-bottom: 0px;
}

.statusContainer {
  margin-top: 5px;
  border-top: 1px solid white;
  p {
    margin: 5px;
  }
}

body .MuiTooltip-popper[data-popper-placement="top-start"] .MuiTooltip-tooltip {
  margin-bottom: 4px;
  margin-left: 0px;
}

body
  .MuiTooltip-popper[data-popper-placement="bottom-start"]
  .MuiTooltip-tooltip {
  margin-top: 4px;
  margin-left: 0px;
}

body .MuiTooltip-popper[data-popper-placement="top-end"] .MuiTooltip-tooltip {
  margin-bottom: 4px;
  margin-right: 0px;
}

body
  .MuiTooltip-popper[data-popper-placement="bottom-end"]
  .MuiTooltip-tooltip {
  margin-top: 4px;
  margin-right: 0px;
}

.zoomIndicator {
  position: absolute;
  bottom: 50px;
  right: 0px;
  background-color: #000;
  color: #fff;
  opacity: 0.5;
  font-weight: bolder;
  font-size: 14px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
}
.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
  z-index: 100;
}

.boxInfo {
  font-size: 12px;
  font-weight: 700;
  transition: opacity 200ms;
  border: 10px solid white;

  & .target:hover {
    & ~ .infoText {
      opacity: 1;
    }
    & ~ .infoBox {
      opacity: 0.75;
    }
  }
  & .infoText {
    opacity: 0;
  }
  & .infoBox {
    opacity: 0;
  }
}


@keyframes borderDance{
  from{
    stroke-dashoffset: 0;
  };
  to{
    stroke-dashoffset: 100;
  };
}

.dancing-border{
  stroke-width: 2px;
  stroke: #fff;
  fill: none;
  stroke-dasharray: 5px;
  animation-name: borderDance;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.main_image {
  box-shadow: 0px 0px 10px black;
}

.extra_image {
  box-shadow: 0px 0px 10px black;
  filter: brightness(50%);

  &:hover {
    filter: brightness(100%);
  }
}

button.connectedButton {
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
  padding: 10px;
  border-radius: 0px;
}

#imageContainer {
  touch-action: none;
}


.arrowExpandButton {
  margin: 14px 0 5px 14px;
  cursor: pointer;
}

.annotationToggleListWrapper {
  .annotationBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 8px;
    margin-left: 20px;

    .annotationCircle{
      margin: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: #999999 1px solid;
      margin-left: -12px;
    }

    .annotationCount {
      font-weight: 600;
      font-size: 15px;
      margin: 0;
      margin-left: 7px;
    }
  }

  .annotationForceIncluded {
    font-style: italic;
  }
}

.dialogFlex {
  display: flex;
  flex-direction: row;
}
