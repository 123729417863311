.closebutton {
  position: "absolute";
  top: 10px;
  right: 10px;
  background-color: #d00;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.closebutton:hover {
  background-color: #f00;
}

.importBoxContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
}

.logButton {
  position: absolute;
  top: 10px;
  right: 80px;
  background-color: #dd0;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.closebutton:hover {
  background-color: #ee0;
}

.savebutton {
  position: absolute;
  top: 10px;
  right: 80px;
  background-color: #0d0;
  color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.savebutton:hover {
  background-color: #0f0;
}

.title {
  text-align: center;
  margin-top: 30px;
  font-size: 32px;
}

.boxContainer {
  position: absolute;
  width: 80vw;
  height: 70vh;
  box-shadow: 0px 0px 1px 1px #eee;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  top: calc(50% - 40vh);
  left: calc(50% - 40vw);
  z-index: 99999;
  overflow-y: auto;
}

.tableContainer {
  margin: 40px;
}

.folderPath {
  background-color: #eee;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.clickable {
  color: #22f;
  cursor: pointer;
}

.fileList {
  list-style-type: none;
  margin: 20px;
}

.fileList li {
  border-top: 1px solid black;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fileList li:last-child {
  border-bottom: 1px solid black;
}

.listHead {
  border-top: none !important;
  font-weight: 700;
}

.uploadScript {
  margin-left: 40px;
  margin-right: 40px;
  background: rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
}

.uploadObject {
  width: 22%;
  margin: calc(1.5% - 4px);
  text-align: center;
  height: 100px;
  box-shadow: 0px 0px 5px black;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
}
.uploadObject:hover {
  background-color: #fff;
}
.abortButtonDisabled {
  padding-left: 20px;
  padding-right: 20px;
  background-color: none;
}

.abortButton {
  margin: 10px;
  background-color: #faa;
}
.jobTable {
  width: 100%;
  text-align: center;
}

.createJobButton {
  background-color: #0a0;
}

.logWindow {
  background-color: #0d1f23;
  padding: 10px;
  padding-left: 20px;
}

.logWindow pre {
  color: #fefefe;
}

.dropZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ddeeff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  cursor: pointer;
  border: 2px dashed black;
}

.dropZone svg {
  font-size: 100px;
}

.react-tabs {
  height: calc(100% - 90px);
}

.react-tabs__tab-panel {
  height: 100%;
  position: relative;
}

.fileList {
  display: inline-grid;
  grid-template-columns: repeat(8, 1fr [col-start]);
  overflow-y: scroll;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

@media only screen and (max-width: 1600px) {
  .fileList {
    grid-template-columns: repeat(7, 1fr [col-start]);
  }
}

@media only screen and (max-width: 1400px) {
  .fileList {
    grid-template-columns: repeat(6, 1fr [col-start]);
  }
}

@media only screen and (max-width: 1200px) {
  .fileList {
    grid-template-columns: repeat(5, 1fr [col-start]);
  }
}

@media only screen and (max-width: 1000px) {
  .fileList {
    grid-template-columns: repeat(4, 1fr [col-start]);
  }
}

@media only screen and (max-width: 800px) {
  .fileList {
    grid-template-columns: repeat(3, 1fr [col-start]);
  }
}

@media only screen and (max-width: 600px) {
  .fileList {
    grid-template-columns: repeat(2, 1fr [col-start]);
  }
}

.fileList .fileItem .clearItem {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  color: white;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 100%;
}

.fileList .fileItem .clearItem:hover {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 20px;
  height: 20px;
  color: white;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 100%;
}

.fileList .fileItem {
  position: relative;
  text-align: center;
  background-color: #ffffff;
  padding: 0px 0px 0px 0px;
  border-radius: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.previewImage {
  max-width: 100%;
  max-height: 80vh;
}

.fileList .fileItem:hover {
  background-color: #ffffff;
  box-shadow: 0px 0px 2px black;
  cursor: pointer;
}

.fileList .fileItem .fileTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;
  font-weight: 600;
}

.uploadContainer {
  display: flex;
  flex-flow: column;
  max-height: 100%;
}
.folderList {
  flex: 1;
  overflow: auto;
}
.footer {
  margin-top: 10px;
}

.current {
  box-shadow: 0px 0px 2px black;
}

.onlineBar {
  width: 100%;
  font-size: large;
  color: #fff;
  background-color: #d00;
  text-align: center;
}

.failed {
  background-color: #ef8068 !important;
}
.uploaded {
  background-color: #ddffdd !important;
}

.footer button {
  min-width: 200px; /* minimum width */
  margin: 10px;
}

.buttonPanel {
  display: inline-grid;
  flex: 1;
  width: 100%;
  margin: 0px;
  grid-template-columns: 1fr 1fr 2fr;
  padding: 0px;
}

@media only screen and (max-width: 900px) {
  .buttonPanel {
    grid-template-columns: repeat(1, 1fr [col-start]);
  }
}

.finished {
  width: 100%;
  text-align: center;
  background-color: #ddffdd;
  padding-top: 20px;

  padding-bottom: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.topBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.topBar h2 {
  text-align: center;
}

.folderNameInput {
  align-self: center;
  margin-bottom: 5px !important;
  min-width: 500px;
}

.folderNames {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.cloudFolder {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px black;
  cursor: pointer;
}
.cloudFolder:hover {
  box-shadow: 0px 0px 5px black;
}
div.folderNameInput {
  margin-left: 10px;
}

.statsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.statsRow .card {
  flex: 1;
  max-width: fit-content;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  margin: 10px;
  padding: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.statsRow .card p {
  margin: 5px;
}
