@import "colors.scss";

#maptoggle {
  color: $text-dark;
  border-radius: 5px;
  width: fit-content;
  padding: 5px 10px;

  div.row {
    justify-content: space-between;
    text-align: right;
    & p {
      flex: 1;
    }
    cursor: pointer;
  }

  .MuiSwitch-switchBase {
    color: $text-dark;

    &.Mui-checked{
      color: $skyqraft-blue;
    }
    transition-duration: 300ms;

    & + .MuiSwitch-track {
      background-color: white;
    }
    &.Mui-checked + .MuiSwitch-track {
      background-color: $skyqraft-blue;
    }
  }
}
