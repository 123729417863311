// Set text colors
$text-dark: #404040;
$text-light: #fff;

// Set theme colors
$skyqraft-blue: #0078eb;
$skyqraft-blue-hover: #2994f8;
$secondary-color: #019b4e;
$secondary-color-hover: #0db963;
$error-color: #ce2523;
$error-color-hover: #e9504d;
$success-light: #ddffdd;
$success: #77ff77;
$info-light: #ddeeff;
$info: #77bbff;
$danger-light: #ffdddd;
$danger: #ff6666;
$error-light: #ffdddd;
$error: #dd3333;
$warning: #ffef00;
$selected: rgba(0, 183, 255, 0.2);
$hover-black: rgba(0, 0, 0, 0.1);
$disabled-black: rgba(0, 0, 0, 0.1);

// Specific colors
$black-overlay: rgba(0, 0, 0, 0.37);
