@import "colors.scss";

div.menuItem {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  &.disabled {
    background-color: $disabled-black;
    cursor: initial;
  }

  p {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .grow {
    flex: 1;
  }

  &.hoverable {
    cursor: pointer!important;
  }

  &.hoverable:hover {
    background-color: $hover-black;
  }

  &.active {
    background-color: $selected;
  }

  &.section {
    cursor: auto;
  }
}
