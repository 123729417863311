
#imageViewerMenu{
  z-index:3;
  margin: 0px 10px;
  position: absolute;
  bottom:0px;
  left:0px;
  right: 0;

  .MuiCollapse-wrapperInner{
    padding-bottom:0px;
  }
  .menuItem{
    background-color: white;
    display:flex;
    align-items: center;
    justify-content: center;
    width:40px;

    &:first-of-type{
      border-top-left-radius: 10px;
    }
    &:last-of-type{
      border-top-right-radius:10px;
    }
  }

  .verticalMenu{
    max-height: 400px;
    overflow-y: scroll;
    width:46px;
    .menuItem{
      &:first-of-type{
        border-top-left-radius: 0px;
      }
      &:last-of-type{
        border-top-right-radius:0px;
      }
    }

  }
}
