#rangeSelector {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 5px;
  position: relative;
  width: 100px;
}
#thermalContainer{
  z-index:10;
  display:flex;
}

#thermal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  overflow: hidden;
  z-index:10;
}

#tempScale {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding: 20px;
  overflow: hidden;
}

html {
  background-color: black;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
