.addForm {
  display: flex;
  flex-direction: column;
  & > div {
    margin-top: 20px;
  }
  & > div:first {
    margin-top: 0px;
  }
}
.hidden-input input{
  -webkit-text-security: disc;
  text-security: disc;
}

#popover {
  button.saveButton {
    color: white;
    background-color: #282;
    &:hover {
      background-color: #4a4;
    }
  }
}

#missionList details{
  cursor: pointer;
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: calc( 100% - 8px );
  pointer-events: all;

  &:hover{
    background-color: rgba(0, 0, 0, 0.07);
  }

  & details{
    &:hover{
      background-color: rgba(0, 0, 0, 0.07);
    }
    & div.menuItem {
      &:hover{
        background-color: rgba(255, 255,255, 0.3);
      }
    }
  }


}
