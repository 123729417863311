@import "breakpoints.scss";

#layout {
  display: flex;
  flex-direction: row;

  @include sm {
    flex-direction: column-reverse;
  }
  @include xs {
    flex-direction: column-reverse;
  }


  #placeholder {
    background-color: green;
    flex: 1;
  }
}
