.canvas {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.zoomIndicator {
  position: absolute;
  bottom: 16px;
  right: 0px;
  background-color: #000;
  color: #fff;
  opacity: 0.5;
  font-weight: bolder;
  font-size: 14px;
  padding: 4px;
  padding-left: 10px;
  padding-right: 10px;
}
.fixedRegionLabel {
  position: absolute;
  z-index: 10;
  top: 10px;
  left: 10px;
  opacity: 0.5;
  transition: opacity 500ms;
  &:hover {
    opacity: 1;
  }
}
