.objectContainer {
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .group {
    width: calc(100% - 20px);
    border-radius: 10px;
    margin: 5px 0px;
    box-shadow: 0px 0px 5px #999;
    &.changed {
      background-color: rgb(255, 244, 190) !important;
    }

    .content {
      padding: 10px;
      background-color: #ecedee;
      table {
        border-spacing: 0;
        width: 100%;

        text-align: left;

        tr.changed {
          background-color: rgb(255, 244, 190) !important;
        }
      }
      ul {
        width: 100%;
        padding: 0px;
        margin: 5px 10px;
        .item {
          display: flex;
          & > * {
            padding-left: 10px;
          }

          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          & input {
            padding: 10px;
            text-align: center;
          }
          z-index: 5;
        }
        .item:hover {
          box-shadow: 0px 0px 10px #777;
          z-index: 10;
          position: relative;
        }
        .item:nth-child(2n) {
          background: #ddd;
        }
        .item:nth-child(2n + 1) {
          background: #fff;
        }
      }
    }
  }
  .category-title > .MuiAccordionSummary-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .Mui-expanded {
      padding: 0px;
    }
  }
  .MuiButton-label {
    color: #fff;
  }

  .undo-button-container {
    padding-bottom: 20px;
  }

  .buttonContainer {
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .button.Mui-disabled {
      color: #bbb;
    }
    .button.saveButton {
      background-color: #282;
      &:hover {
        background-color: #4a4;
      }
    }
    .button.restoreButton {
      background-color: #22a;
      &:hover {
        background-color: #44c;
      }
    }
    .button.closeButton {
      background-color: #c22;
      &:hover {
        background-color: #e44;
      }
    }
  }


  .button.addButton {
    position: relative;
    width: calc(100% - 20px);
    background-color: #282 !important;
    &:hover {
      background-color: #4a4 !important;
    }
  }
  .button.playButton {
    color: #22a;
  }
  .button.deleteButton {
    color: #e44;
  }
  .button.deployedButton {
    color: grey;
    &.deployed {
      color: black;
    }
  }
  .category-select {
    padding-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    flex: 1;
  }
}
.MuiAccordion-root:before {
  display: none;
}

.popup-wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .popup-container {
    background: white;
    min-width: 500px;
    padding: 10px 20px;
    border-radius: 5px;

    .field-row {
      width: 100%;
      input[type="color"] {
        width: 30px;
        height: 30px;
        padding: 0px;
      }
    }
    .split {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      p {
        width: 200px;
      }
    }

    button {
      padding: 10px 15px;
      margin: 5px;
      border: none;
      border-radius: 5px;
      font-size: large;
      cursor: pointer;
      color: white;
    }
    button.submit {
      background-color: #282;
      &:hover {
        background-color: #4a4;
      }
      &:disabled,
      &[disabled] {
        background-color: #9a9;
        cursor: auto;
      }
    }
    button.cancel {
      background-color: #c22;
      &:hover {
        background-color: #e44;
      }
    }
  }
}
