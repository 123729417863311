@import "colors.scss";

html, body, #root, #layout{
  height:100%
}
body{
  background-color: #f0f0f0;
}


:root {
  /* Colors */
  --amazonOrange: #009900; /* Redefined to dark green */
  --lightAmazonOrange: #ffac31;
  --darkAmazonOrange: #e88b01;
  --squidInk: #232f3e;
  --lightSquidInk: #31465f;
  --deepSquidInk: #152939;
  --grey: #828282;
  --lightGrey: #c4c4c4;
  --silver: #e1e4ea;
  --darkBlue: #31465f;
  --red: #dd3f5b;
  --white: #ffffff;
  --light-blue: #00a1c9;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: #dadbe2;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bfc4ce;
  border-radius: 5px;
}

.settingsButton {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 25px;
  right: 55px;
  text-align: center;
  background-color: white;
  width: 60px;
  height: 32px;
  padding: 2px;
  cursor: pointer;

  border-radius: 3px;
}

button.greenButton {
  background-color: $success;
}
button.greenButton:hover {
  background-color: $success;
}
.redIcon {
  color: $error;
  cursor: pointer;
}

.clickable {
  color: inherit !important;
}

div.Toast__toast___XXr3v {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  padding: 20px;
}

div.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

body button.primaryButton {
  background-color: $skyqraft-blue;
  color: $text-light;

  &:hover {
    background-color: $skyqraft-blue-hover;
    color: $text-light;
  }
}

body button.secondaryButton {
  background-color: $secondary-color;
  color: $text-light;
  &:hover {
    background-color: $secondary-color-hover;
    color: $text-light;
  }
}

body button.errorButton {
  background-color: $error-color;
  color: $text-light;
  &:hover {
    background-color: $error-color-hover;
    color: $text-light;
  }
}

.centered{
  width:100%;
  height:100%;
  display:grid;
  place-items: center;
}
