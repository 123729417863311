.poleImageButtonContainer{
    justify-content: center;
    width: 369px;
    background-color: #eaeff1;
    display: flex;
    flex-direction: row;
    box-shadow: -3px 0px 0px 0px rgba(0,0,0,0.75);
    padding-bottom: 10px;


    .poleImageButtonWrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;


        .poleImageButton{
            width: 87px;
            height: 32px;
            border-radius: 62px;
            background: #D9D9D9;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;

            .poleImageElement{
                height: 32px;
                width: 48px;
                border-radius: 62px;
                background-color: #006AEA7D;
                position: absolute;
                margin: 0 0 0 38px;
                transition: margin 0.1s ease-in-out;
            }

            .poleImageElement.active {
                margin:0 38px 0 0;
            }
        }
    }

}

.poleImageTitleContainer {
  justify-content: center;
  width: 369px;
  background-color: #eaeff1;
  display: flex;
  flex-direction: row;
  box-shadow: -3px 0px 0px 0px rgba(0,0,0,0.75);
  padding-top: 6px;

}
