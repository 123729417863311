.mapListButtonContainer{
       display: flex;
       align-items: center;
       z-index: 1;

    .mapListButtonWrapper{
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;


        .mapListButton{
            width: 87px;
            height: 32px;
            border-radius: 62px;
            background: #D9D9D9;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;

            .mapListElement{
                height: 32px;
                width: 48px;
                border-radius: 62px;
                background-color: #006AEA7D;
                position: absolute;
                margin: 0 0 0 38px;
                transition: margin 0.1s ease-in-out;
            }

            .mapListElement.active {
                margin:0 38px 0 0;
            }
        }
    }

}
