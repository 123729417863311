.clusterPreview {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.clusterPreviewImage {
  margin: auto;
  border: 2px solid rgba(255, 255, 255, 0.3);
  margin-left: 5px;
  min-width: 50px;
  height: 100px;
}

.clusterPreviewImage img {
  height: 100px;
}

.clusterPreviewImage:first-child {
  margin-left: 0px;
}

.clusterPreviewImage:hover {
  border: 2px solid rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.imageOverlay:hover {
  border: 7px solid rgba(255, 255, 255, 0.8);
}

.imageOverlay {
  border: 7px solid lightskyblue;
}

.sliderContainer {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0px;
  margin: 0px;
}
.sliderTrack {
  display: flex;
  flex-direction: row;
  width: fit-content;
}

.arrowIndicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
  border-radius: 100%;
  padding: 5px;
  border: 1px solid transparent;
  top: 50%;
}

.arrowIndicator.left {
  left: -15px;
  transform: translate(50%, -50%) rotate(180deg);
}

.arrowIndicator.right {
  right: -15px;
  transform: translate(-50%, -50%);
}

.arrowIndicator:hover {
  border: 1px solid black;
}

.arrowIndicator.right svg {
  padding-left: 1px;
  margin-right: -1px;
}
.arrowIndicator.left svg {
  padding-left: 1px;
  margin-right: -1px;
}

.sliderContainer {
  transform: rotateX(180deg);
  z-index: 10;
  height: fit-content;
}
.sliderTrack {
  transform: rotateX(180deg);
}
.sliderContainer::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.sliderContainer::-webkit-scrollbar-track {
  border-radius: 1px;
}

/* Handle */
.sliderContainer::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}
