@import "breakpoints.scss";

#time-selector {
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 20;

  span {
    color: #404040;
    font-size: medium;
  }
  @include md {
    left: 40%;
    span {
      font-size: medium;
    }
  }

  @include sm {
    transform: translate(0px, 0px);
    left: 40px;
    span {
      font-size: small;
    }
  }

  @include xs {
    transform: translate(0px, 0px);
    left: 40px;
    span {
      font-size: small;
    }
  }
}
