
.MuiStepLabel-root {

  .completed .MuiStepLabel-iconContainer{
    background-color:green!important;
  }


  .MuiStepLabel-iconContainer{
    border-radius: 100%;
    padding:4px;
    color:white;

    &.Mui-active{
      background-color:blue;
    }

    &.Mui-error{
      background-color:red;
    }
  }



}
#uploadView span.MuiTabs-indicator{
  background-color: black;
  transition: none;
}
