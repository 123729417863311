body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
body .MuiTypography-body1,
body .MuiButton-label,
body .MuiChip-root,
body .MuiInput-root,
body .MuiTooltip-tooltip,
body .MuiButton-root {
  font-family: "Manrope", sans-serif;
}

body .Toastify__toast {
  color: #fbfbfb;
  font-weight: 400;
}

body .amplify-visually-hidden{
  display:none;
}

body .MuiTooltip-tooltip {
  color: #fbfbfb;
  background-color: #404040;
  font-weight: 400;
}
body .MuiTooltip-arrow {
  color: #404040;
}

h1,
h2,
h3 {
  font-weight: 700;
}

h4,
h5,
h6 {
  font-weight: 300;
}

p {
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
