.objectDetails {
  width: calc(100% - 20px);
  padding: 10px;
}

.typeSelector {
  display: flex;
  flex-direction: column;
  & > * {
    flex-grow: 1;
    padding: 3px;
    text-align: left;
    align-content: stretch;
  }
}

.extraPadding {
  padding-left: 10px;
}

.objectTypeContainer {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px 2px;
}

.objectTypeItem {
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.issueTypeContainer {
  display: flex;
  flex-direction: row;
  // margin-top: 5px;

  padding: 2px;
  margin: 0px 6px 11px 6px;
  border: solid;
  border-color: #0000007a;
  border-width: 1px;
  border-radius: 9px;

  & .issueCategoryContainer {
    width: 40%;
  }

  & .issueItemContainer {
    width: 60%;
  }
}

.issueTypeContainer .issueCategoryContainer,
.issueTypeContainer .issueItemContainer,
.severityContainer {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  & > * {
    width: 100%;
    cursor: pointer;
    padding: 5px;
    & p {
      margin: 0px;
      padding: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: small;
    }
    &:hover {
      background-color: #efefef;
    }
    &:nth-child(2n) {
      background-color: #f8f8f8;
      &:hover {
        background-color: #efefef;
      }
    }
  }
}

.commentAndHistory {
  button.Mui-disabled.Mui-disabled svg {
    fill: rgba(0, 0, 0, 0.25);
  }
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  & input {
    font-size: medium;
  }
  & .addCommentField {
    flex-grow: 1;
  }
  & * {
    border-color: #666 !important;
    border-width: 1px !important;
    color: #ffffff;
  }
}
.historyContainer {
  margin-top: 10px;
  .historyItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    & .historyIcon {
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
      & .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(51, 51, 51);
        color: white;
        border-radius: 100%;
        text-align: center;
        width: 11px;
        height: 11px;
        padding: 5px;
        font-size: 10px;
      }
    }
    & .historyText {
      flex-grow: 1;
    }
    & p {
      margin: 5px;
      font-size: small;
    }
    &:nth-child(2n) {
      background-color: #EDEDED;
    }
  }
}

.lastUpdated {
  font-size: small;
  font-style: italic;
  padding-left: 10px;
  padding-top: 5px;
  margin-top: 0px;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  textarea {
    width: calc(100% - 10px);
    border-radius: 5px;
    padding: 5px;
    min-height: 50px;
    height: fit-content;
  }

  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  height: fit-content;
  align-items: center;
}


.objectCollector {
  margin: 5px;
  background-color: #fff;
  color: #333 !important;
  border-radius: 8px;
}

.objectCollector .comment{
  text-align: center;
  font-size: 12px;
  margin-top: 2px;
}

.objectCollector:first-child {
  background-color: #fff;
  margin-top: 0px;
}

.collectorTitle {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  height: 50px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & .titleText {
    height: fit-content;
    & > * {
      margin: 0px;
    }
    & h2 {
      font-size: small;
    }
    & p {
      font-size: smaller;
      font-style: italic;
    }

    #clickExpand {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      max-width: 130px;
    }
  }

  & .visibleIcon {
    margin-right: 7px;
  }
}

.horizontal {
  display: flex;
  flex-direction: row;
  &.center {
    align-items: center;
    justify-content: center;
  }
}

.expandIcon {
  width: 30px !important;
  margin-right: 5px;
}

.object {
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f1f2f3;
}
.objectActions svg {
  height: 20px;
}

.userInfo * {
  margin: 0px;
  font-size: small;
  &.comment {
    font-style: italic;
  }
}

.avatar {
  padding-right: 5px;
}

.clickableIcon {
  cursor: pointer;
}

.searchValue {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0px;
  padding: 2px 0px 2px 5px;

  color: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.searchOption {
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0px;
  width: 100%;
  padding: 2px 0px 2px 5px;
  color: white;
  &:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-bottom: 10px;
  }
  &.highlighted,
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.colorDots {
  display: flex;
  flex-direction: row;
  padding: 5px;
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid #999;
    margin: 0px;
    padding: 0px;
    position: relative;
    margin-left: -12px;
  }
  .dot:nth-child(1) {
    margin-left: 0px;
  }
}

.classList {
  text-overflow: ellipsis;
  .classRow.center {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .order {
    width: 325px;
  }

  .classRow,
  .order {
    max-width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .order {
    width: 220px;
    align-items: center;
  }
  .classRow {
    margin-right: 10px;
  }
}

.selectTypeChip {
  max-width: 100%;
  width: strech;
}

.commentSectionWrapper{
  display: flex;
  justify-content: center;
  margin: 22px 0 8px 0;

.commentSectionIssue {
  width: 340px;
  display: grid;
  border: solid;
  grid-template-columns: 170px 85px 85px;
  grid-template-rows: 5px auto 5px;
  border-width: 1px;
  border-radius: 9px;
  border-color: #33333378;

  .commentText {
    padding: 6px;
    overflow-wrap:break-word;
    flex-grow: 2;
    border-right: 1px solid;
    margin: 0;
    text-align: center;
    font-size: 12px;
    border-color: #33333378;
    .commentInput {
      width: 155px;
      resize: none;
      border: none;
      font-weight: 400;
      color: #404040;
      font-family: "Manrope", sans-serif;
    }
    .commentInput:focus-visible{
      outline: none;
    }

}

.commentTextLast {
  padding: 6px;
  overflow-wrap: break-word;
  flex-grow: 2;
  margin: 0;
  text-align: center;
  font-size: 12px;
}

.commentHeader {
  top: -21px;
  position: relative;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;

  .headerText {
    width: 65px;
    height: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerTextFirst {
    width: 86px;
    height: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

}
}


.commentSection {
  width: 340px;
  display: grid;
  border: solid;
  grid-template-columns: 0 170px 170px;
  grid-template-rows: 5px auto 5px;
  border-width: 1px;
  border-radius: 9px;
  border-color: #33333378;

  .commentText {
    padding: 6px;
    overflow-wrap:break-word;
    flex-grow: 2;
    border-right: 1px solid;
    margin: 0;
    text-align: center;
    font-size: 12px;
    border-color: #33333378;
    .commentInput {
      resize: none;
      border: none;
      font-weight: 400;
      color: #404040;
      font-family: "Manrope", sans-serif;
    }
    .commentInput:focus-visible{
      outline: none;
    }

}

.commentTextLast {
  padding: 6px;
  overflow-wrap: break-word;
  flex-grow: 2;
  margin: 0;
  text-align: center;
  font-size: 12px;
}

.commentHeader {
  top: -21px;
  position: relative;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;

  .headerText {
    width: 65px;
    height: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .headerTextFirst {
    width: 86px;
    height: 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
  }

}
}
}
