@import "colors.scss";
@import "breakpoints.scss";

.header {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 10;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);

  @include xs {
    flex-direction: column-reverse;
    align-items: center;
  }

  .headerActions,
  .toolActions {
    display: flex;
    flex-direction: row;
    height: 50px;

    button {
      display: grid;
      place-items: center;
      width: 60px;
      padding: 0px;
      margin: 0px;
      border-radius: 0px;
      min-width: none;

      &:hover {
        background-color: $hover-black;
      }
    }
  }
}

.fileInfo {
  align-items: center;
  flex-grow: 1;
  display: flex;
  color: grey;
  margin-right: 16px;
  font-size: 12px;
  padding-left: 16px;
}
