

.precision-recall-accuracy-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.precision-recall-accuracy{
  display:grid;
  grid-template-rows: 10px 1fr 1fr;
  grid-template-columns: 30px 1fr 1fr;
  width: 80%;
  height: 80%;

  grid-row-gap: 6px;
}

.grid-item {
  background-color: rgba(224, 56, 56, 1);
  opacity: 0.9;
  justify-self: stretch;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5%;
  font-size: large;
  font-weight: bold;
  margin: 0px 5px 5px 5px;
}

.grid-item:hover {
  opacity: 1;
  font-weight: bolder;
  margin: 0px 4px 4px 4px;

}

.true {
  background-color: rgba(55, 160, 55, 1);
}

.positive:hover {
    opacity: 1;
    font-weight: bolder;
    margin: 0px 4px 4px 4px;;
}


.grid-label {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 12px;
}

#statistics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  gap: 30px 0px;
  margin: 30px 0 100px 0;
}

.statisticitem{
  width: 500px;
  height: 550px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  background:#fff;
  border-color: rgba(0, 0, 0, 0.24);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
  padding: 20px;
}

.statisticitem h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 20px 0 30px 10px;
  color: #443b3b;
}

.tablewrapper{
  width: 100vw;
  margin: 50px 32px 50px 32px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.statbox {
  height: 180px;
  width: 180px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.24);
  background:#fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.12);
}

.statbox h2 {
  text-align: center;
  margin-top: 10%;
  font-size: 42px;
}

.statbox p {
  text-align: center;
  font-size: 15px;
  margin-top: 25px;
  color: #443b3b;
}
