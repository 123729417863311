#temperatureSlider {
  width: 20px;

  margin: 20px;
  z-index:10;
  margin-left: 75px;
  height: calc(100vh - 160px);
  margin: 20px;
  position:absolute;
  #colors {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  #rangeSelector {
    position: absolute;
    top:0px;
    left:0px;
    width: 100%;
    height: 100%;
    margin:0px;
    padding:0px;
  }

  .colorIndicator {
    height: 10px;
    width: 30px;
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 0px 5px black;
  }

  #top,
  #bottom {
    position:absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      margin: 0px;
      width: 70px;
      text-align: center;
    }
    left: -5px;
    z-index:10;

  }

  #top{
    transform: translate( 0px, 50%);
  }
  #bottom{
    transform: translate( 0px, -50%);
  }

}
.relativor{
  height:100%;
  width:100%;
  position:relative;
}
