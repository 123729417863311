@import "colors.scss";

#infoWindow {
  position: absolute;
  top: 100px;
  right: 0px;
  z-index: 10;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  & > * {
    pointer-events: all;
  }
}

#infoWindow .blackbg {
  padding: 10px;

  color: white;
  border-radius: 5px;

  h2 {
    font-size: large;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    padding: 5px;
    margin: 0px;
  }

  #flight-leader {
    tr td:nth-child(2) {
      text-align: right;
    }
    a {
      color: $text-light;
    }
  }
}
