.infoBox {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
}

.infoBoxWrapper {
  pointer-events: none;
}


@keyframes borderDance{
  from{
    stroke-dashoffset: 0;
  }
  to{
    stroke-dashoffset: 100;
  }
}

.highlightBox{
  z-index: 5;
  overflow: visible;
  transition: opacity 500ms;

  &.highlighted{
    z-index: 6;
  }

  &:not(.highlighted) {
    opacity: 0;
  }
  &:not(.highlighted):hover {
    opacity: 1;
  }

  & path {
    stroke-width: 2;
    stroke: #fff;
    fill: none;
    stroke-dasharray: 5;
    animation-name: borderDance;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }
}
