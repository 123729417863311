.objectDetails {
  width: calc(100% - 20px);
  padding: 10px;
  // background-color: #fff;
}

.typeSelector {
  display: flex;
  flex-direction: column;
  & > * {
    flex-grow: 1;
    padding: 3px;
    text-align: left;
    align-content: stretch;
  }
}

.extraPadding {
  padding-left: 10px;
}

.objectTypeContainer {
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px 2px;
}

.objectTypeItem {
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.issueTypeContainer {
  display: flex;
  flex-direction: row;
  margin-top: 5px;

  & .issueCategoryContainer {
    width: 40%;
  }

  & .issueItemContainer {
    width: 60%;
  }
}

.issueTypeContainer .issueCategoryContainer,
.issueTypeContainer .issueItemContainer,
.severityContainer {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  & > * {
    width: 100%;
    cursor: pointer;
    padding: 5px;
    & p {
      margin: 0px;
      padding: 0px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: small;
    }
    &:hover {
      background-color: #efefef;
    }
    &:nth-child(2n) {
      background-color: #f8f8f8;
      &:hover {
        background-color: #efefef;
      }
    }
  }
}

.commentAndHistory {
  button.Mui-disabled.Mui-disabled svg {
    fill: rgba(0, 0, 0, 0.25);
  }
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  & input {
    font-size: medium;
  }
  & .addCommentField {
    flex-grow: 1;
  }
  & * {
    border-color: #666 !important;
    border-width: 1px !important;
    color: #333;
  }
}
.historyContainer {
  margin-top: 10px;
  .historyItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    & .historyIcon {
      text-align: center;
      padding-right: 10px;
      padding-left: 10px;
      & .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(51, 51, 51);
        color: white;
        border-radius: 100%;
        text-align: center;
        width: 11px;
        height: 11px;
        padding: 5px;
        font-size: 10px;
      }
    }
    & .historyText {
      flex-grow: 1;
    }
    & p {
      margin: 5px;
      font-size: small;
    }
    &:nth-child(2n) {
      background-color: #EDEDED;
    }
  }
}

.lastUpdated {
  font-size: small;
  font-style: italic;
  padding-left: 10px;
  padding-top: 5px;
  margin-top: 0px;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  textarea {
    width: calc(100% - 10px);
    border-radius: 5px;
    padding: 5px;
    min-height: 50px;
    height: fit-content;
  }

  position: relative;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  height: fit-content;
  align-items: center;
}
