.marker{
  position:absolute;
  width: 14px;
  height: 14px;
  z-index: 3;
  border: 1px solid black;
  border-radius: 100%;
  opacity:0.4;
  cursor:pointer;
  transition: margin .3s;

  &.preview{
    border: 1px solid black;
    opacity:1;

  }
  &.normal{
    opacity:1;
    border: 1px solid black;
  }
  &.filtered{
    opacity: 1;
    border: 1px solid white;
  }
  &.highlighted, &.active{
    border: 2px solid white;
  }
}

div.lidarMarker{
  border: 2px solid red;
  padding: 4px;
  width: fit-content;
  border-radius: 100%;
  transform: translate( -50%, -50% );
  cursor: pointer;
  &.active{
    border: 2px solid white!important;
  }
  & > div.lidarDot{
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background-color: red;
    border: 1px solid black;
  }
}

div.clearanceMarker{
  border: 2px solid red;
  padding: 4px;
  width: fit-content;
  border-radius: 100%;
  cursor: pointer;
  transform: translate( -50%, -50% );
  &.active{
    border: 2px solid white!important;
  }
  & > div.clearanceDot{
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background-color: #0077FF;
    border: 1px solid black;
  }
}

.gpsPositionMarker{
  pointer-events: none;
  transform: translate( -10px , -10px);
}

.openedMarker{
  pointer-events: none;
  transform: translate( -5px , -5px);
  position: absolute;
  font-size: 5px;
  width:14px;
  height:14px;
}

.crosshair{
  pointer-events: none;
  transform: translate( -25px , -25px);
  position: absolute;
  z-index: 100000000;
}

@import "colors.scss";


.pole{
  transform: translate(-50%, -100%);
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index:2;


  .insulator, .crossarm, .beam{
    background-color: white;
    border:1px solid black;
    border-radius: 3px;
    z-index:3;
  }
  &.unseen{
    background-color: #75b5ff;
    .insulator, .crossarm, .beam{
      background-color: #75b5ff;
    }
  }


  .insulatorRow{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width:30px;
    .insulator{
      width:5px;
      height:5px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom: none;
      margin-bottom: -1px;
    }
  }
  .crossarm{
    width: 30px;
    height:4px;
  }
  .beam{
    width:4px;
    height:30px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
    margin-top:-1px;
  }
  .poleLabel{
    padding: 5px;
    background: #000000;
    color: #ffffff;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    border-radius: 5px;
}
}

.connectedLine svg line {
  stroke: white;
  stroke-width: 3px;
  stroke-dasharray: 5;
}

.connectedLine {
  pointer-events: none;
}
