.progressBar {
  height: 20px;
  background-color: #0d1f23;
  width: 100%;
  margin: 10px 0px 10px 0px;
  border-radius: 10px;
  overflow: hidden;
}
.progressBar div {
  background-color: #393;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
}
