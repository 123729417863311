// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 900px;

// Tablets and small desktops
$screen-lg: 1200px;

// Large tablets and desktops
$screen-xl: 1600px;

@mixin xs {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm}) and (max-width: #{$screen-md}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md}) and (max-width: #{$screen-lg}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg}) and (max-width: #{$screen-xl}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}
