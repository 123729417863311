@import "breakpoints.scss";


.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  overflow: hidden;
  &.fullscreen {
    position: absolute;
    z-index: 99999;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
}
.workspace {
  background-color: #dedede;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;

  @include xs {
    flex-direction: column-reverse;
  }
}
.imageCanvasContainer {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}
.noImageSelected {
  font-weight: bold;
  font-size: 32px;
  color: grey;
}
.sidebarContainer {
  width: 370px;
  flex-shrink: 0;
  overflow-y: auto;
  background-color: #dedede;
  border-left: 1px solid grey;
  z-index: 9;
  height: 100%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  & .SidebarBoxContainer {
    margin: 0px;
    margin-top: 20px;
    border-radius: 0px;
    & .header {
      background-color: #fff;
    }
    & .expandedContent {
      background-color: #ededed;
    }
  }
  & .SidebarBoxContainer:first-child {
    margin-top: 0px;
  }

  @include xs {
    height: 230px;
    width: 100%;
  }
}
