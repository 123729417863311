
.titleText {
    height: fit-content;


    #clickExpand {
      font-family: 'manrope', sans-serif;
      font-size: 11px;
      font-weight: 700;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      max-width: 130px;
    }
  }
